import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  StudentPage,
  TeacherPage,
  LoginPage,
  ParentPage,
  TChatPage,
  SChatPage,
  PronouncePage,
  PracticePage,
  AssessPage,
  StudyPlanPage,
  PreNotesPage,
  LessonPlanPage,
  NotesChatPage,
  MathChatPage,
  HomePage,
  MainPage,
  ProfilePage,
  HandbookCheckPage,
  AdminPage,
  PreAssessment,
  CameraPage,
  DictationPage,
  BookshelfPage,
  EnglishReading,
  EnglishWriting,
  SpeechPage,

} from "./pages/page-routes";
import ProtectedRoute from "./protectedRoutes"; // Import the ProtectedRoute

interface RroutesProps {
  studentID: string | null;
  setStudentID: React.Dispatch<React.SetStateAction<string | null>>;
}

export const Rroutes: React.FC<RroutesProps> = ({
  studentID,
  setStudentID,
}) => {
  return (
    <Routes>
      <Route
        path="/login"
        element={<LoginPage setStudentID={setStudentID} />}
      />
      <Route
        path="/student"
        element={
          <ProtectedRoute element={<StudentPage />} studentID={studentID} />
        }
      />
      <Route
        path="/teacher"
        element={
          <ProtectedRoute element={<TeacherPage />} studentID={studentID} />
        }
      />
      <Route
        path="/tchat"
        element={
          <ProtectedRoute element={<TChatPage />} studentID={studentID} />
        }
      />
      <Route path="/parent" element={<ParentPage />} />
      <Route
        path="/schat"
        element={
          <ProtectedRoute element={<SChatPage />} studentID={studentID} />
        }
      />
      <Route
        path="/pronounce"
        element={
          <ProtectedRoute element={<PronouncePage />} studentID={studentID} />
        }
      />
      <Route
        path="/practice"
        element={
          <ProtectedRoute element={<PracticePage />} studentID={studentID} />
        }
      />
      <Route
        path="/assess"
        element={
          <ProtectedRoute element={<AssessPage />} studentID={studentID} />
        }
      />
      <Route
        path="/studyplan"
        element={
          <ProtectedRoute element={<StudyPlanPage />} studentID={studentID} />
        }
      />
      <Route
        path="/lessonplan"
        element={
          <ProtectedRoute element={<LessonPlanPage />} studentID={studentID} />
        }
      />
      <Route
        path="/noteschat"
        element={
          <ProtectedRoute element={<NotesChatPage />} studentID={studentID} />
        }
      />
      <Route
        path="/prenotes"
        element={
          <ProtectedRoute element={<PreNotesPage />} studentID={studentID} />
        }
      />
      <Route
        path="/mathchat"
        element={
          <ProtectedRoute element={<MathChatPage />} studentID={studentID} />
        }
      />
      <Route
        path="/home"
        element={
          <ProtectedRoute element={<HomePage />} studentID={studentID} />
        }
      />
      <Route
        path="/main"
        element={
          <ProtectedRoute element={<MainPage />} studentID={studentID} />
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute element={<ProfilePage />} studentID={studentID} />
        }
      />
      <Route
        path="/handbook"
        element={
          <ProtectedRoute
            element={<HandbookCheckPage />}
            studentID={studentID}
          />
        }
      />
      <Route path="/admin" element={<AdminPage />} />
      {/* <Route path="/camera" element={<CameraPage />} /> */}
      <Route
        path="/preassessment"
        element={
          <ProtectedRoute element={<PreAssessment />} studentID={studentID} />
        }
      />
      <Route
        path="/dictation"
        element={
          <ProtectedRoute element={<DictationPage />} studentID={studentID} />
        }
      />
      <Route
        path="/bookshelf"
        element={
          <ProtectedRoute element={<BookshelfPage />} studentID={studentID} />
        }
      />
      <Route
        path="/english-reading"
        element={
          <ProtectedRoute element={<EnglishReading />} studentID={studentID} />
        }
      />
      <Route
        path="/english-writing"
        element={
          <ProtectedRoute element={<EnglishWriting />} studentID={studentID} />
        }
      />
      <Route path="*" element={<Navigate to="/login" />} />
      {/* <Route path="/speech" element={<SpeechPage />} /> */}
    </Routes>
  );
};

export default Rroutes;
