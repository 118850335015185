import React, { useState, useEffect } from "react";
import axios from "axios";
import Lottie from "react-lottie";
import * as hub from "langchain/hub";
import { ChatPromptTemplate } from "@langchain/core/prompts";

import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./component/style.css";
import generatingAnimation from "../../../assests/lotties/generatingAnimation.json";
import checkingAnimation from "../../../assests/lotties/checkingAnimation.json";
import Modal from "../../../components/Modal/Modal";
import { BarLoader } from "react-spinners";
import Dictionary from "../../../components/Dictionary/Dictionary"; // Import the Dictionary component
import Speech from "../../../components/Speech/Speech";

interface EnglishReadingQuestionsJson {
  reading_paragraph: string;
  questions: EnglishReadingQuestion[];
}

interface EnglishReadingQuestion {
  question_num: string;
  markdown: string;
}

interface EnglishReadingReviewResponse {
  [key: string]: EachEnglishReadingReviewResponse;
}

interface EachEnglishReadingReviewResponse {
  question: string;
  submitted_answer: string;
  correct_answer: string;
  feedback: string;
  reference: string;
}

const EnglishReadingPage: React.FC = () => {
  const location = useLocation();
  const { studentId, selectedChapter, mode } = location.state || {};
  const navigate = useNavigate();

  const [readingData, setReadingData] =
    useState<EnglishReadingQuestionsJson | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const [isFromMemory, setIsFromMemory] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [isAttachedReadingFile, setIsAttachedReadingFile] = useState(false);
  const [attachmentReadingFileText, setAttachmentReadingFileText] =
    useState<string>("Upload Answer Image");
  const [selectedReadingFile, setSelectedReadingFile] = useState<File | null>(
    null
  );
  const [multipleSelectedReadingFile, setMultipleSelectedReadingFile] =
    useState<FileList | null>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState<boolean>(false);
  const [editableReadingAnswer, setEditableReadingAnswer] = useState<string[]>(
    []
  );
  const [reviewResponse, setReviewResponse] =
    useState<EnglishReadingReviewResponse | null>(null);
  const [reviewResponseList, setReviewResponseList] = useState<
    EachEnglishReadingReviewResponse[]
  >([]);
  const [wiggle, setWiggle] = useState<boolean>(false);
  const [hint, setHint] = useState<string | null>(null);
  const [hintLoading, setHintLoading] = useState<boolean>(false);
  const [sumbitted, setSubmitted] = useState<boolean>(false);
  const [speechInstuction, setSpeechInstruction] = useState<string>("");
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  // ========================== reading & question-section ==========================
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (selectedChapter && mode && studentId && !hasFetched) {
      //   console.log("generate reading", selectedChapter, mode, studentId);
      const formData = new FormData();
      formData.append("student_id", studentId);
      formData.append("chapter", selectedChapter);
      formData.append("para_type", mode);
      generateReading(formData);
      setHasFetched(true);
    }
  }, [selectedChapter, mode, studentId, hasFetched]);

  const renderParagraphWithDictionary = (paragraph: string) => {
    return paragraph.split(" ").map((word, index) => (
      <>
        <Dictionary
          key={index}
          word={word}
          selectedWord={selectedWord}
          setSelectedWord={setSelectedWord}
          tooltipVisible={tooltipVisible}
          setTooltipVisible={setTooltipVisible}
          tooltipPosition={tooltipPosition}
          setTooltipPosition={setTooltipPosition}
        />{" "}
      </>
    ));
  };

  const generateReading = async (formData: FormData) => {
    if (!readingData) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/exercise/reading`,
          formData
        );

        // console.log(response.data.payload);
        setIsFromMemory(response.data.payload.from_memory);
        setReadingData(response.data.payload.questions);

        const completePrompt = await fetchLangSmithPrompt(
          response.data.payload.questions.reading_paragraph,
          JSON.stringify(response.data.payload.questions.questions)
        );

        setSpeechInstruction(completePrompt);
        // console.log(speechInstruction);
        setLoading(false);
        setSubmitted(false);
        setCurrentQuestionIndex(0); // Reset to the first question
      } catch (error) {
        setLoading(false);
        console.error("There was an error generating the reading!", error);
      }
    }
  };

  const fetchLangSmithPrompt = async (
    paragraph: string,
    questions: string
  ): Promise<string> => {
    const prompt = await hub.pull<ChatPromptTemplate>(
      "aibo-ui-english-reading-interaction",
      { apiKey: process.env.REACT_APP_LANGSMITH_API_KEY }
    );
    const langsmithPrompt = prompt.lc_kwargs.promptMessages[0].prompt.template;
    const completePrompt =
      langsmithPrompt + "課文: " + paragraph + "問題: " + questions;
    return completePrompt;
  };

  const handleNextQuestion = () => {
    if (
      readingData &&
      currentQuestionIndex < readingData.questions.length - 1
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setHint(null);
      triggerWiggle();
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setHint(null);
      triggerWiggle();
    }
  };

  const triggerWiggle = () => {
    // console.log("wiggle");
    setWiggle(true);
    setTimeout(() => setWiggle(false), 500); // Remove the class after the animation completes
  };

  const triggerFileSelect = () => {
    document.getElementById("reading-answer-image-input")?.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAttachedReadingFile(false);
    setSelectedReadingFile(null);
    setMultipleSelectedReadingFile(null);
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files.length > 1) {
        setMultipleSelectedReadingFile(event.target.files);
        setIsAttachedReadingFile(true);
      } else {
        setSelectedReadingFile(event.target.files[0]);
        setIsAttachedReadingFile(true);
      }
    }
  };

  useEffect(() => {
    if (selectedReadingFile) {
      setAttachmentReadingFileText(selectedReadingFile.name);
    } else if (multipleSelectedReadingFile) {
      setAttachmentReadingFileText(
        `${multipleSelectedReadingFile.length} files selected`
      );
    } else {
      setAttachmentReadingFileText("Upload Answer Image");
    }
  }, [selectedReadingFile, multipleSelectedReadingFile]);

  const handleSubmitAnswer = () => {
    setUploadLoading(true);
    const { studentId } = location.state || {};
    const formData = new FormData();
    if (selectedReadingFile) {
      formData.append("review_images", selectedReadingFile);
    } else if (multipleSelectedReadingFile) {
      for (let i = 0; i < multipleSelectedReadingFile.length; i++) {
        formData.append("review_images", multipleSelectedReadingFile[i]);
      }
    }
    formData.append("student_id", studentId);
    verifyReadingAnswer(formData);
  };

  const verifyReadingAnswer = async (formData: FormData) => {
    try {
      setIsVerifyModalOpen(true);
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/exercise/reading/verify`,
        formData
      );
      setEditableReadingAnswer(
        Object.keys(response.data.payload).map(
          (item) => response.data.payload[item]
        )
      );
      //   console.log(editableReadingAnswer);
    } catch (error) {
      console.error("Error updating english progress:", error);
    } finally {
      setUploadLoading(false);
    }
  };

  const handleEditableChange = (index: number, value: string) => {
    const updatedList = [...editableReadingAnswer];
    updatedList[index] = value;
    setEditableReadingAnswer(updatedList);
  };

  const handleAddInput = (index: number) => {
    const updatedList = [...editableReadingAnswer];
    updatedList.splice(index + 1, 0, "");
    setEditableReadingAnswer(updatedList);
  };

  const handleRemoveInput = (index: number) => {
    const updatedList = [...editableReadingAnswer];
    updatedList.splice(index, 1);
    setEditableReadingAnswer(updatedList);
  };

  const handleReviewConfirm = () => {
    setUploadLoading(true);
    if (editableReadingAnswer.every((item) => item.trim() !== "")) {
      // Define the type for the dictionary
      type AnswerDict = { [key: string]: string };

      // Turn list into dict with question number as key
      const editableReadingAnswerDict: AnswerDict =
        editableReadingAnswer.reduce((acc, answer, index) => {
          acc[`${index + 1}`] = answer;
          return acc;
        }, {} as AnswerDict);

      const editableReadingAnswerString = JSON.stringify(
        editableReadingAnswerDict
      );
      //   console.log("edit", editableReadingAnswerString);

      const { studentId } = location.state || {};
      const formData = new FormData();
      formData.append("student_answer", editableReadingAnswerString);
      formData.append("student_id", studentId);
      UploadReadingAnswerReview(formData);
    } else {
      alert("Please fill in all the inputs.");
    }
  };

  const UploadReadingAnswerReview = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/exercise/reading/review`,
        formData
      );
      //   console.log(response.data.payload);
      setReviewResponse(response.data.payload);
      const ResponseList = Object.keys(response.data.payload).map(
        (key) => response.data.payload[key]
      );
      setReviewResponseList(ResponseList);
      //   console.log(reviewResponseList);
      triggerWiggle();
    } catch (error) {
      console.error("Error confirming reading answer:", error);
    } finally {
      setUploadLoading(false);
      setCurrentQuestionIndex(0);
      setIsVerifyModalOpen(false);
      setSubmitted(true);
    }
  };

  const handleFetchHint = async () => {
    setHintLoading(true);
    setHint(null);
    const formData = new FormData();
    formData.append("student_id", studentId);
    formData.append(
      "question_id",
      readingData?.questions[currentQuestionIndex].question_num || ""
    );
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/exercise/reading/hint`,
        formData
      );
      console.log(response.data.payload);
      setHint(response.data.payload);
    } catch (error) {
      console.error("Error fetching hint:", error);
    } finally {
      setHintLoading(false);
    }
  };

  // ========================== header ==========================

  const handleNagivateHome = () => {
    navigate("/student", { state: { studentId } });
  };

  const handleNagivateBookshelf = () => {
    navigate("/bookshelf", { state: { studentId } });
  };

  return (
    <>
      <header className="header">
        <div className="header-left">
          <div className="title">
            <strong>English Reading</strong>
          </div>
        </div>
        <div className="header-right">
          <button className="header-button" onClick={handleNagivateHome}>
            Home
          </button>
          <button className="header-button" onClick={handleNagivateBookshelf}>
            Bookshelf
          </button>
        </div>
      </header>
      <div className="english-reading-page">
        {loading ? (
          <div className="loading-animation">
            <>
              <Lottie
                options={{
                  animationData: generatingAnimation, // Replace with the actual animation data
                  loop: true,
                  autoplay: true,
                }}
                height={300}
                width={300}
              />
              <div className="loading-text">
                Generating reading exercise on Chapter: {selectedChapter}
              </div>
            </>
          </div>
        ) : (
          readingData && (
            <>
              <div className="reading-section">
                <div className="reading-paragraph">
                  <div className="reading-paragraph-wrapper">
                    <div className="reading-image">Image Placeholder</div>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {/* <div className="reading-paragraph-text"> */}
                      {
                        //   renderParagraphWithDictionary(
                        readingData.reading_paragraph
                        //   )
                      }
                      {/* </div> */}
                    </ReactMarkdown>
                  </div>
                  <div className="speech-button">
                    <Speech
                      instructions={speechInstuction}
                      page="reading"
                      page_subject="english"
                      studentId={studentId}
                    />
                    <hr />
                  </div>
                  <div className="review-section">
                    <button
                      className="reading-select-file"
                      onClick={triggerFileSelect}
                    >
                      📤{attachmentReadingFileText}
                    </button>
                    <input
                      id="reading-answer-image-input"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                      multiple
                    />
                    {isAttachedReadingFile ? (
                      <button
                        className="reading-submit-button"
                        onClick={handleSubmitAnswer}
                      >
                        Submit Answer
                      </button>
                    ) : (
                      <button
                        className="reading-submit-button"
                        style={{
                          backgroundColor: "grey",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        Submit Answer
                      </button>
                    )}
                  </div>
                </div>
                {readingData.questions.length > 0 && (
                  <div className="reading-question-section">
                    <div className="reading-question-wrapper">
                      <div className="reading-question-number">
                        Question {currentQuestionIndex + 1})
                      </div>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {readingData.questions[currentQuestionIndex].markdown}
                      </ReactMarkdown>
                    </div>
                    <div className="reading-utils-section">
                      {!reviewResponse ? (
                        hint ? (
                          <div className="reading-hint">
                            <div className="hint-lightbulb">💡</div>
                            {hint}
                          </div>
                        ) : (
                          <div
                            className="clickable-hint"
                            onClick={handleFetchHint}
                          >
                            {hintLoading ? (
                              <div className="loading-bar-wrapper">
                                <BarLoader
                                  color="#ffffff"
                                  loading={hintLoading}
                                />
                              </div>
                            ) : (
                              <div className="getHintText">💡Get Hint</div>
                            )}
                          </div>
                        )
                      ) : (
                        <>
                          {reviewResponseList && (
                            <>
                              <div
                                className={`reading-review-response ${
                                  wiggle ? "wiggle" : ""
                                }`}
                              >
                                {reviewResponseList[currentQuestionIndex] && (
                                  <>
                                    {reviewResponseList[currentQuestionIndex]
                                      .feedback === "" ? (
                                      <div className="reviewComment reading-correct">
                                        ✅ Correct!
                                      </div>
                                    ) : (
                                      <div className="reviewComment reading-incorrect">
                                        ❌ Try again!
                                      </div>
                                    )}
                                    <div>
                                      <strong>Your Answer:</strong>{" "}
                                      {
                                        reviewResponseList[currentQuestionIndex]
                                          .submitted_answer
                                      }
                                    </div>
                                    {reviewResponseList[currentQuestionIndex]
                                      .feedback !== "" ? (
                                      <div>
                                        <strong>Feedback:</strong>{" "}
                                        {
                                          reviewResponseList[
                                            currentQuestionIndex
                                          ].feedback
                                        }
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <div className="navigation-buttons">
                        <button
                          className={
                            currentQuestionIndex === 0
                              ? `previous-button disabled-navigation-button`
                              : `previous-button`
                          }
                          onClick={handlePreviousQuestion}
                          disabled={currentQuestionIndex === 0}
                        >
                          &#x2190;
                        </button>
                        {currentQuestionIndex ===
                          readingData.questions.length - 1 &&
                        sumbitted === true ? (
                          <button
                            className={`next-button`}
                            onClick={handleNagivateBookshelf}
                          >
                            Finish
                          </button>
                        ) : (
                          <></>
                        )}
                        <button
                          className={
                            currentQuestionIndex ===
                            readingData.questions.length - 1
                              ? `next-button disabled-navigation-button`
                              : `next-button`
                          }
                          onClick={handleNextQuestion}
                          disabled={
                            currentQuestionIndex ===
                            readingData.questions.length - 1
                          }
                        >
                          &#x2192;
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Modal show={isFromMemory && isModalOpen} onClose={closeModal}>
                <>
                  Seems like you generated before, complete the current exercise
                  to generate a new one!
                </>
              </Modal>
              <Modal
                show={isVerifyModalOpen}
                onClose={() => setIsVerifyModalOpen(false)}
              >
                <div className="reading-verify-modal">
                  {uploadLoading ? (
                    <>
                      <Lottie
                        options={{
                          animationData: checkingAnimation, // Replace with the actual animation data
                          loop: true,
                          autoplay: true,
                        }}
                        height={300}
                        width={300}
                      />
                      <div className="loading-text">
                        AIBO is checking your answers...
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="editable-reading-answer-title">
                        Confirm your answers
                      </div>
                      {editableReadingAnswer.map((item, index) => (
                        <div className="each-reading-answer-input" key={index}>
                          <div className="reading-answer-input-index">
                            {index + 1})
                          </div>
                          <textarea
                            className="editable-reading-answer-text-area"
                            value={item}
                            onChange={(e) =>
                              handleEditableChange(index, e.target.value)
                            }
                            rows={1}
                            cols={50}
                          />
                          <button
                            className="add-reading-answer-button"
                            onClick={() => handleAddInput(index)}
                          >
                            +
                          </button>
                          <button
                            className="remove-reading-answer-button"
                            onClick={() => handleRemoveInput(index)}
                          >
                            -
                          </button>
                        </div>
                      ))}
                      {editableReadingAnswer.every(
                        (item) => item.trim() !== ""
                      ) ? (
                        <button
                          className="reading-review-button"
                          onClick={handleReviewConfirm}
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          className="reading-review-button"
                          style={{
                            backgroundColor: "grey",
                            color: "rgba(255, 255, 255, 0.7)",
                          }}
                        >
                          Confirm
                        </button>
                      )}
                    </>
                  )}
                </div>
              </Modal>
            </>
          )
        )}
      </div>
    </>
  );
};

export default EnglishReadingPage;
