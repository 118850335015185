import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

interface DictationUploadProps {
  onUploadSuccess: (response: any) => void;
  setLoading: (loading: boolean) => void;
}

const DictationUpload: React.FC<DictationUploadProps> = ({
  onUploadSuccess,
  setLoading,
}) => {
  const [attachedDictationList, setAttachedDictationList] = useState(false);
  const [attachmentDictationFileText, setAttachmentDictationFileText] =
    useState<string>("上傳默書範圍");
  const [selectedDictationList, setSelectedDictationList] =
    useState<File | null>(null);
  const [multipleSelectedDictationList, setMultipleSelectedDictationList] =
    useState<FileList | null>(null);
  const location = useLocation();

  const triggerFileSelect = () => {
    document.getElementById("dictation-list-image-input")?.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttachedDictationList(false);
    setSelectedDictationList(null);
    setMultipleSelectedDictationList(null);
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files.length > 1) {
        setMultipleSelectedDictationList(event.target.files);
        setAttachedDictationList(true);
      } else {
        setSelectedDictationList(event.target.files[0]);
        setAttachedDictationList(true);
      }
    }
  };

  const handleSubmitFile = () => {
    setLoading(true);
    const { studentId } = location.state || {};
    const formData = new FormData();
    if (selectedDictationList) {
      formData.append("dictation_list_images", selectedDictationList);
    } else if (multipleSelectedDictationList) {
      for (let i = 0; i < multipleSelectedDictationList.length; i++) {
        formData.append(
          "dictation_list_images",
          multipleSelectedDictationList[i]
        );
      }
    }
    formData.append("student_id", studentId);
    UploadDictationList(formData);
  };

  const UploadDictationList = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/dictation/upload`,
        formData
      );
      onUploadSuccess(response.data.payload);
    } catch (error) {
      console.error("Error updating english progress:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDictationList) {
      setAttachmentDictationFileText(selectedDictationList.name);
    } else if (multipleSelectedDictationList) {
      setAttachmentDictationFileText(
        `${multipleSelectedDictationList.length} files selected`
      );
    } else {
      setAttachmentDictationFileText("上傳默書範圍");
    }
  }, [selectedDictationList, multipleSelectedDictationList]);

  return (
    <div>
      <button className="dictation-button" onClick={triggerFileSelect}>
        📤{attachmentDictationFileText}
      </button>
      <input
        id="dictation-list-image-input"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileSelect}
        multiple
      />
      {attachedDictationList ? (
        <button
          className="dictation-button"
          onClick={handleSubmitFile}
          style={{ backgroundColor: "green" }}
        >
          提交範圍
        </button>
      ) : (
        <button
          className="dictation-button"
          style={{ backgroundColor: "grey", color: "rgba(255, 255, 255, 0.7)" }}
        >
          提交範圍
        </button>
      )}
    </div>
  );
};

export default DictationUpload;
