import React, { useState, useEffect } from "react";
import axios from "axios";
import Lottie from "react-lottie";
import * as hub from "langchain/hub";
import { ChatPromptTemplate } from "@langchain/core/prompts";

import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./component/style.css";
import generatingAnimation from "../../../assests/lotties/generatingAnimation.json";
import checkingAnimation from "../../../assests/lotties/checkingAnimation.json";
import Modal from "../../../components/Modal/Modal";
import { BarLoader } from "react-spinners";
import Dictionary from "../../../components/Dictionary/Dictionary"; // Import the Dictionary component
import Speech from "../../../components/Speech/Speech";

interface EnglishWritingQuestionsJson {
  notes: string;
  questions: EnglishWritingQuestion[];
  word_list: string[];
}

interface EnglishWritingQuestion {
  question_num: string;
  markdown: string;
}

interface EnglishWritingReviewResponse {
  [key: string]: EachEnglishWritingReviewResponse;
}

interface EachEnglishWritingReviewResponse {
  question: string;
  submitted_answer: string;
  correct_answer: string;
  feedback: string;
  reference: string;
}

const EnglishWritingPage: React.FC = () => {
  const location = useLocation();
  const { studentId, selectedChapter } = location.state || {};
  const navigate = useNavigate();

  const [writingData, setWritingData] =
    useState<EnglishWritingQuestionsJson | null>(null);
  const [wordList, setWordList] = useState<string[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const [isFromMemory, setIsFromMemory] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [isAttachedWritingFile, setIsAttachedWritingFile] = useState(false);
  const [attachmentWritingFileText, setAttachmentWritingFileText] =
    useState<string>("Upload Answer Image");
  const [selectedWritingFile, setSelectedWritingFile] = useState<File | null>(
    null
  );
  const [multipleSelectedWritingFile, setMultipleSelectedWritingFile] =
    useState<FileList | null>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState<boolean>(false);
  const [editableWritingAnswer, setEditableWritingAnswer] = useState<string[]>(
    []
  );
  const [reviewResponse, setReviewResponse] =
    useState<EnglishWritingReviewResponse | null>(null);
  const [reviewResponseList, setReviewResponseList] = useState<
    EachEnglishWritingReviewResponse[]
  >([]);
  const [wiggle, setWiggle] = useState<boolean>(false);
  // const [hint, setHint] = useState<string | null>(null);
  // const [hintLoading, setHintLoading] = useState<boolean>(false);
  const [speechInstuction, setSpeechInstruction] = useState<string>("");

  const [sumbitted, setSubmitted] = useState<boolean>(false);

  // ========================== writing & question-section ==========================
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (selectedChapter && studentId && !hasFetched) {
      console.log("generate writing");
      const formData = new FormData();
      formData.append("student_id", studentId);
      formData.append("chapter", selectedChapter);
      generateWriting(formData);
      setHasFetched(true);
    }
  }, [selectedChapter, studentId, hasFetched]);

  const generateWriting = async (formData: FormData) => {
    if (!writingData) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/exercise/writing`,
          formData
        );

        console.log(response.data.payload);
        setIsFromMemory(response.data.payload.from_memory);
        console.log(isModalOpen);
        setWritingData(response.data.payload.questions);

        const completePrompt = await fetchLangSmithPrompt(
          response.data.payload.questions.notes.toString(),
          JSON.stringify(response.data.payload.questions.questions),
          response.data.payload.word_list.toString()
        );

        setSpeechInstruction(completePrompt);

        setLoading(false);
        setSubmitted(false);
        setCurrentQuestionIndex(0); // Reset to the first question
        setWordList(response.data.payload.word_list);
      } catch (error) {
        setLoading(false);
        console.error("There was an error generating the writing!", error);
      }
    }
  };

  const fetchLangSmithPrompt = async (
    paragraph: string,
    questions: string,
    word_list: string[]
  ): Promise<string> => {
    const prompt = await hub.pull<ChatPromptTemplate>(
      "aibo-ui-english-reading-interaction",
      { apiKey: process.env.REACT_APP_LANGSMITH_API_KEY }
    );
    const langsmithPrompt = prompt.lc_kwargs.promptMessages[0].prompt.template;
    const completePrompt =
      langsmithPrompt +
      "課文: " +
      paragraph +
      "\n填充題詞語表: " +
      word_list +
      "\n問題: " +
      questions;
    console.log(completePrompt);
    return completePrompt;
  };

  const handleNextQuestion = () => {
    if (
      writingData &&
      currentQuestionIndex < writingData.questions.length - 1
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      triggerWiggle();
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      triggerWiggle();
    }
  };

  const triggerWiggle = () => {
    console.log("wiggle");
    setWiggle(true);
    setTimeout(() => setWiggle(false), 500); // Remove the class after the animation completes
  };

  const triggerFileSelect = () => {
    document.getElementById("writing-answer-image-input")?.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAttachedWritingFile(false);
    setSelectedWritingFile(null);
    setMultipleSelectedWritingFile(null);
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files.length > 1) {
        setMultipleSelectedWritingFile(event.target.files);
        setIsAttachedWritingFile(true);
      } else {
        setSelectedWritingFile(event.target.files[0]);
        setIsAttachedWritingFile(true);
      }
    }
  };

  useEffect(() => {
    if (selectedWritingFile) {
      setAttachmentWritingFileText(selectedWritingFile.name);
    } else if (multipleSelectedWritingFile) {
      setAttachmentWritingFileText(
        `${multipleSelectedWritingFile.length} files selected`
      );
    } else {
      setAttachmentWritingFileText("Upload Answer Image");
    }
  }, [selectedWritingFile, multipleSelectedWritingFile]);

  const handleSubmitAnswer = () => {
    setUploadLoading(true);
    const { studentId } = location.state || {};
    const formData = new FormData();
    if (selectedWritingFile) {
      formData.append("review_images", selectedWritingFile);
    } else if (multipleSelectedWritingFile) {
      for (let i = 0; i < multipleSelectedWritingFile.length; i++) {
        formData.append("review_images", multipleSelectedWritingFile[i]);
      }
    }
    formData.append("student_id", studentId);
    verifyWritingAnswer(formData);
  };

  const verifyWritingAnswer = async (formData: FormData) => {
    try {
      setIsVerifyModalOpen(true);
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/exercise/writing/verify`,
        formData
      );
      setEditableWritingAnswer(
        Object.keys(response.data.payload).map(
          (item) => response.data.payload[item]
        )
      );
      console.log(editableWritingAnswer);
    } catch (error) {
      console.error("Error updating english progress:", error);
    } finally {
      setUploadLoading(false);
    }
  };

  const handleEditableChange = (index: number, value: string) => {
    const updatedList = [...editableWritingAnswer];
    updatedList[index] = value;
    setEditableWritingAnswer(updatedList);
  };

  const handleAddInput = (index: number) => {
    const updatedList = [...editableWritingAnswer];
    updatedList.splice(index + 1, 0, "");
    setEditableWritingAnswer(updatedList);
  };

  const handleRemoveInput = (index: number) => {
    const updatedList = [...editableWritingAnswer];
    // Writing;
    updatedList.splice(index, 1);
    setEditableWritingAnswer(updatedList);
  };

  const handleReviewConfirm = () => {
    setUploadLoading(true);
    if (editableWritingAnswer.every((item) => item.trim() !== "")) {
      // Define the type for the dictionary
      type AnswerDict = { [key: string]: string };

      // Turn list into dict with question number as key
      const editableWritingAnswerDict: AnswerDict =
        editableWritingAnswer.reduce((acc, answer, index) => {
          acc[`${index + 1}`] = answer;
          return acc;
        }, {} as AnswerDict);

      const editableWritingAnswerString = JSON.stringify(
        editableWritingAnswerDict
      );
      console.log("edit", editableWritingAnswerString);

      const { studentId } = location.state || {};
      const formData = new FormData();
      formData.append("student_answer", editableWritingAnswerString);
      formData.append("student_id", studentId);
      UploadWritingAnswerReview(formData);
    } else {
      alert("Please fill in all the inputs.");
    }
  };

  const UploadWritingAnswerReview = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/exercise/writing/review`,
        formData
      );
      console.log(response.data.payload);
      setReviewResponse(response.data.payload);
      const ResponseList = Object.keys(response.data.payload).map(
        (key) => response.data.payload[key]
      );
      setReviewResponseList(ResponseList);
      console.log(reviewResponseList);
      triggerWiggle();
    } catch (error) {
      console.error("Error confirming writing answer:", error);
    } finally {
      setUploadLoading(false);
      setCurrentQuestionIndex(0);
      setIsVerifyModalOpen(false);
      setSubmitted(true);
    }
  };

  // ========================== header ==========================

  const handleNagivateHome = () => {
    navigate("/student", { state: { studentId } });
  };

  const handleNagivateBookshelf = () => {
    navigate("/bookshelf", { state: { studentId } });
  };

  return (
    <>
      <header className="header">
        <div className="header-left">
          <div className="title">
            <strong>English Writing</strong>
          </div>
        </div>
        <div className="header-right">
          <button className="header-button" onClick={handleNagivateHome}>
            Home
          </button>
          <button className="header-button" onClick={handleNagivateBookshelf}>
            Bookshelf
          </button>
        </div>
      </header>
      <div className="english-writing-page">
        {loading ? (
          <div className="loading-animation">
            <>
              <Lottie
                options={{
                  animationData: generatingAnimation, // Replace with the actual animation data
                  loop: true,
                  autoplay: true,
                }}
                height={300}
                width={300}
              />
              <div className="loading-text">
                Generating writing exercise on Chapter: {selectedChapter}
              </div>
            </>
          </div>
        ) : (
          writingData && (
            <>
              <div className="writing-section">
                <div className="writing-paragraph">
                  <div className="writing-paragraph-wrapper">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {writingData.notes}
                      {/* </div> */}
                    </ReactMarkdown>
                  </div>
                  <div className="speech-button">
                    <Speech
                      instructions={speechInstuction}
                      page="reading"
                      page_subject="english"
                      studentId={studentId}
                    />
                    <hr />
                  </div>
                  <div className="review-section">
                    <button
                      className="writing-select-file"
                      onClick={triggerFileSelect}
                    >
                      📤{attachmentWritingFileText}
                    </button>
                    <input
                      id="writing-answer-image-input"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                      multiple
                    />
                    {isAttachedWritingFile ? (
                      <button
                        className="writing-submit-button"
                        onClick={handleSubmitAnswer}
                      >
                        Submit Answer
                      </button>
                    ) : (
                      <button
                        className="writing-submit-button"
                        style={{
                          backgroundColor: "grey",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        Submit Answer
                      </button>
                    )}
                  </div>
                </div>
                {writingData.questions.length > 0 && (
                  <div className="writing-question-section">
                    <div>
                      {writingData && wordList && (
                        <div className="writing-word-list-container">
                          <>
                            <h2 className="word-list-title">Word List</h2>
                            <div className="writing-word-list">
                              {wordList.map((word, index) => (
                                <span key={index}>
                                  {word}
                                  {index !== wordList.length - 1 ? " / " : ""}
                                </span>
                              ))}
                            </div>
                          </>
                        </div>
                      )}
                    </div>
                    <div className="writing-question-wrapper">
                      <div className="writing-question-number">
                        Question {currentQuestionIndex + 1})
                      </div>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {writingData.questions[currentQuestionIndex].markdown}
                      </ReactMarkdown>
                    </div>
                    <div className="reading-utils-section">
                      {!reviewResponse ? (
                        <></>
                      ) : (
                        <>
                          {reviewResponseList && (
                            <>
                              <div
                                className={`writing-review-response ${
                                  wiggle ? "wiggle" : ""
                                }`}
                              >
                                {reviewResponseList[currentQuestionIndex] && (
                                  <>
                                    {reviewResponseList[currentQuestionIndex]
                                      .feedback === "" ? (
                                      <div className="reviewComment writing-correct">
                                        ✅ Correct!
                                      </div>
                                    ) : (
                                      <div className="reviewComment writing-incorrect">
                                        ❌ Try again!
                                      </div>
                                    )}
                                    <div>
                                      <strong>Your Answer:</strong>{" "}
                                      {
                                        reviewResponseList[currentQuestionIndex]
                                          .submitted_answer
                                      }
                                    </div>
                                    {reviewResponseList[currentQuestionIndex]
                                      .feedback !== "" ? (
                                      <div>
                                        <strong>Feedback:</strong>{" "}
                                        {
                                          reviewResponseList[
                                            currentQuestionIndex
                                          ].feedback
                                        }
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <div className="navigation-buttons">
                        <button
                          className={
                            currentQuestionIndex === 0
                              ? `previous-button disabled-navigation-button`
                              : `previous-button`
                          }
                          onClick={handlePreviousQuestion}
                          disabled={currentQuestionIndex === 0}
                        >
                          &#x2190;
                        </button>
                        {currentQuestionIndex ===
                          writingData.questions.length - 1 &&
                        sumbitted === true ? (
                          <button
                            className={`next-button`}
                            onClick={handleNagivateBookshelf}
                          >
                            Finish
                          </button>
                        ) : (
                          <></>
                        )}
                        <button
                          className={
                            currentQuestionIndex ===
                            writingData.questions.length - 1
                              ? `next-button disabled-navigation-button`
                              : `next-button`
                          }
                          onClick={handleNextQuestion}
                          disabled={
                            currentQuestionIndex ===
                            writingData.questions.length - 1
                          }
                        >
                          &#x2192;
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Modal show={isFromMemory && isModalOpen} onClose={closeModal}>
                <>
                  Seems like you generated before, complete the current exercise
                  to generate a new one!
                </>
              </Modal>
              <Modal
                show={isVerifyModalOpen}
                onClose={() => setIsVerifyModalOpen(false)}
              >
                <div className="writing-verify-modal">
                  {uploadLoading ? (
                    <>
                      <Lottie
                        options={{
                          animationData: checkingAnimation, // Replace with the actual animation data
                          loop: true,
                          autoplay: true,
                        }}
                        height={300}
                        width={300}
                      />
                      <div className="loading-text">
                        AIBO is checking your answers...
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="editable-writing-answer-title">
                        Confirm your answers
                      </div>
                      {editableWritingAnswer.map((item, index) => (
                        <div className="each-writing-answer-input" key={index}>
                          <div className="writing-answer-input-index">
                            {index + 1})
                          </div>
                          <textarea
                            className="editable-writing-answer-text-area"
                            value={item}
                            onChange={(e) =>
                              handleEditableChange(index, e.target.value)
                            }
                            rows={1}
                            cols={50}
                          />
                          <button
                            className="add-writing-answer-button"
                            onClick={() => handleAddInput(index)}
                          >
                            +
                          </button>
                          <button
                            className="remove-writing-answer-button"
                            onClick={() => handleRemoveInput(index)}
                          >
                            -
                          </button>
                        </div>
                      ))}
                      {editableWritingAnswer.every(
                        (item) => item.trim() !== ""
                      ) ? (
                        <button
                          className="writing-review-button"
                          onClick={handleReviewConfirm}
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          className="writing-review-button"
                          style={{
                            backgroundColor: "grey",
                            color: "rgba(255, 255, 255, 0.7)",
                          }}
                        >
                          Confirm
                        </button>
                      )}
                    </>
                  )}
                </div>
              </Modal>
            </>
          )
        )}
      </div>
    </>
  );
};

export default EnglishWritingPage;
