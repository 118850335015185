import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ChapterSelection.css";

interface Textbook {
  publisher: string;
  grade: string;
  subject: string;
}

interface Chapter {
  [key: string]: string;
}

interface ChapterSelectionProps {
  textbook: Textbook;
  studentId: string;
  closeModal: () => void;
}

const ChapterSelection: React.FC<ChapterSelectionProps> = ({
  textbook,
  studentId,
  closeModal,
}) => {
  const [chapters, setChapters] = useState<Chapter>({});
  const [selectedChapter, setSelectedChapter] = useState<string>("");
  const [mode, setMode] = useState<"manual" | "generate">("manual"); // Set default mode to "manual"
  const [exercise, setExercise] = useState<string>("");
  const [alertText, setAlertText] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (textbook) {
      // Fetch chapters from the backend
      axios
        .get(
          `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/textbook/${studentId}/${textbook.subject}/${textbook.publisher}`
        )
        .then((response) => {
          setChapters(response.data.payload);
        })
        .catch((error) => {
          console.error("There was an error fetching the chapters!", error);
        });
    }
  }, [studentId, textbook]);

  const handleConfirm = () => {
    if (!selectedChapter || !mode || !exercise) {
      setAlertText("Please select a chapter, mode, and exercise.");
      return;
    }
    closeModal();
    navigate(`/english-${exercise}`, {
      state: { studentId, selectedChapter, mode },
    });
  };

  return (
    <div className="chapter-selection">
      <h1>Chapter Selection</h1>
      <div className="exercise-selection">
        <button
          className={
            exercise === "reading" ? "selected-exercise-selection" : ""
          }
          onClick={() => setExercise("reading")}
        >
          Reading
        </button>
        <button
          className={
            exercise === "writing" ? "selected-exercise-selection" : ""
          }
          onClick={() => setExercise("writing")}
        >
          Writing
        </button>
        <button className="coming-soon">Speaking</button>
        <button className="coming-soon">Listening</button>
      </div>
      <div className="dropdown">
        <label htmlFor="chapter-select">Choose a chapter: </label>
        <select
          id="chapter-select"
          value={selectedChapter}
          onChange={(e) => setSelectedChapter(e.target.value)}
        >
          <option value="">--Please choose an option--</option>
          {Object.entries(chapters).map(([key, value]) => (
            <option key={key} value={key}>
              Chapter {key}. {value}
            </option>
          ))}
        </select>
      </div>
      {exercise === "reading" ? (
        <div className="mode-selection">
          <label className="switch">
            <input
              type="checkbox"
              checked={mode === "generate"}
              onChange={() =>
                setMode(mode === "manual" ? "generate" : "manual")
              }
            />
            <span
              className="slider round"
              style={
                {
                  "--slider-text": `"${
                    mode === "manual" ? "書本課文" : "生成文章"
                  }"`,
                } as React.CSSProperties
              }
            ></span>
          </label>
          {/* <span className="modeTitle">
                {mode === "manual" ? "書本課文" : "生成文章"}
            </span> */}
        </div>
      ) : (
        <> </>
      )}
      {alertText ? (
        <div className="alertChapterSelection">{alertText}</div>
      ) : (
        <div className="alertChapterSelection"></div>
      )}
      <button
        className="confirmReadingButton"
        onClick={handleConfirm}
        // disabled={!selectedChapter || !mode || !exercise}
        style={{
          backgroundColor: !selectedChapter || !mode || !exercise ? "grey" : "",
        }}
      >
        Confirm
      </button>
    </div>
  );
};

export default ChapterSelection;
