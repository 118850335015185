import React from "react";
import "./Card.css";

interface CardProps {
  title: string;
  description: string;
  icon: any;
  color: string;
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  icon,
  color,
  onClick,
}) => {
  return (
    <button className="card" style={{ borderColor: color }} onClick={onClick}>
      <div className="card-icon">{icon}</div>
      <div className="card-content">
        <h3 className="content-title">{title}</h3>
        <p className="content-desc">{description}</p>
      </div>
    </button>
  );
};

export default Card;
