import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Bookshelf from "../../components/Bookshelf/Bookshelf";

const BookshelfPage: React.FC = () => {
  const location = useLocation();
  const { studentId } = location.state || {};

  return (
    <>
      <Bookshelf studentId={studentId} />
    </>
  );
};

export default BookshelfPage;
