import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

interface DictationAnswerConfirmProps {
  studentAnswers: string[];
  onConfirmSuccess: (response: any) => void;
  setLoading: (loading: boolean) => void;
}

const DictationAnswerConfirm: React.FC<DictationAnswerConfirmProps> = ({
  studentAnswers,
  onConfirmSuccess,
  setLoading,
}) => {
  const [editableAnswers, setEditableAnswers] =
    useState<string[]>(studentAnswers);
  const location = useLocation();

  const handleEditableChange = (index: number, value: string) => {
    const updatedAnswers = [...editableAnswers];
    updatedAnswers[index] = value;
    setEditableAnswers(updatedAnswers);
  };

  const handleAddInput = (index: number) => {
    const updatedList = [...editableAnswers];
    updatedList.splice(index + 1, 0, "");
    setEditableAnswers(updatedList);
  };

  const handleRemoveInput = (index: number) => {
    const updatedList = [...editableAnswers];
    updatedList.splice(index, 1);
    setEditableAnswers(updatedList);
  };

  const handleConfirm = () => {
    setLoading(true);
    if (editableAnswers.every((item) => item.trim() !== "")) {
      const { studentId } = location.state || {};
      const formData = new FormData();
      formData.append(
        "confirmed_student_answer",
        JSON.stringify(editableAnswers)
      );
      formData.append("student_id", studentId);
      UploadStudentAnswers(formData);
    } else {
      alert("Please fill in all the inputs.");
    }
  };

  const UploadStudentAnswers = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/dictation/check`,
        formData
      );
      onConfirmSuccess(response.data.payload);
    } catch (error) {
      console.error("Error confirming student answers:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="editable-dictation-list">
      <div className="dictation-list-wrapper">
        <div className="confirm-dictation-title">確認你的答案</div>
        {editableAnswers.map((item, index) => (
          <div className="each-dictation-input" key={index}>
            <div className="dictation-input-index">{index + 1})</div>
            <textarea
              className="editable-dictation-text-area"
              value={item}
              onChange={(e) => handleEditableChange(index, e.target.value)}
              rows={1}
              cols={50}
            />
            <button
              className="add-dict-button"
              onClick={() => handleAddInput(index)}
            >
              +
            </button>
            <button
              className="remove-dict-button"
              onClick={() => handleRemoveInput(index)}
            >
              -
            </button>
          </div>
        ))}
      </div>
      {editableAnswers.every((item) => item.trim() !== "") ? (
        <button className="dictation-button" onClick={handleConfirm}>
          確認答案
        </button>
      ) : (
        <button
          className="dictation-button"
          style={{ backgroundColor: "grey", color: "rgba(255, 255, 255, 0.7)" }}
        >
          確認答案
        </button>
      )}
    </div>
  );
};

export default DictationAnswerConfirm;
