import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

interface DictationConfirmProps {
  editableDictationList: string[];
  setEditableDictationList: (list: string[]) => void;
  onConfirmSuccess: (response: any) => void;
  setLoading: (loading: boolean) => void;
}

const DictationConfirm: React.FC<DictationConfirmProps> = ({
  editableDictationList,
  setEditableDictationList,
  onConfirmSuccess,
  setLoading,
}) => {
  const location = useLocation();

  const handleEditableChange = (index: number, value: string) => {
    const updatedList = [...editableDictationList];
    updatedList[index] = value;
    setEditableDictationList(updatedList);
  };

  const handleAddInput = (index: number) => {
    const updatedList = [...editableDictationList];
    updatedList.splice(index + 1, 0, "");
    setEditableDictationList(updatedList);
  };

  const handleRemoveInput = (index: number) => {
    const updatedList = [...editableDictationList];
    updatedList.splice(index, 1);
    setEditableDictationList(updatedList);
  };

  const handleConfirm = () => {
    setLoading(true);
    if (editableDictationList.every((item) => item.trim() !== "")) {
      console.log(editableDictationList);
      const editableDictationListString = JSON.stringify(editableDictationList);
      const { studentId } = location.state || {};
      const formData = new FormData();
      formData.append("verified_dictation", editableDictationListString);
      formData.append("student_id", studentId);
      UploadDictationConfirm(formData);
    } else {
      alert("Please fill in all the inputs.");
    }
  };

  const UploadDictationConfirm = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/dictation/verify`,
        formData
      );
      onConfirmSuccess(response.data.payload);
    } catch (error) {
      console.error("Error confirming dictation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="editable-dictation-list">
      <div className="dictation-list-wrapper">
        <div className="confirm-dictation-title">確認默書範圍</div>
        {editableDictationList.map((item, index) => (
          <div className="each-dictation-input" key={index}>
            <div className="dictation-input-index">{index + 1})</div>
            <textarea
              className="editable-dictation-text-area"
              value={item}
              onChange={(e) => handleEditableChange(index, e.target.value)}
              rows={1}
              cols={50}
            />
            <button
              className="add-dict-button"
              onClick={() => handleAddInput(index)}
            >
              +
            </button>
            <button
              className="remove-dict-button"
              onClick={() => handleRemoveInput(index)}
            >
              -
            </button>
          </div>
        ))}
      </div>
      {editableDictationList.every((item) => item.trim() !== "") ? (
        <button className="dictation-button" onClick={handleConfirm}>
          確認範圍
        </button>
      ) : (
        <button
          className="dictation-button"
          style={{ backgroundColor: "grey", color: "rgba(255, 255, 255, 0.7)" }}
        >
          確認範圍
        </button>
      )}
    </div>
  );
};

export default DictationConfirm;
