import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  element: JSX.Element;
  studentID: string | null; // Define the type for studentID
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  studentID,
}) => {
  return studentID ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
