import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

interface DictationCheckProps {
  dictationListResponse: any;
  onCheckSuccess: (response: any) => void;
  setLoading: (loading: boolean) => void;
  onUploadStudentAnswerSuccess: (response: string[]) => void; // New prop
}

const DictationCheck: React.FC<DictationCheckProps> = ({
  dictationListResponse,
  onCheckSuccess,
  setLoading,
  onUploadStudentAnswerSuccess, // New prop
}) => {
  const [attachedDictationCheck, setAttachedDictationCheck] = useState(false);
  const [
    attachmentDictationCheckFileText,
    setAttachmentDictationCheckFileText,
  ] = useState<string>("上傳答案");
  const [selectedDictationCheck, setSelectedDictationCheck] =
    useState<File | null>(null);
  const [multipleSelectedDictationCheck, setMultipleSelectedDictationCheck] =
    useState<FileList | null>(null);
  const location = useLocation();

  const triggerCheckFileSelect = () => {
    document.getElementById("dictation-check-image-input")?.click();
  };

  const handleCheckFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttachedDictationCheck(false);
    setSelectedDictationCheck(null);
    setMultipleSelectedDictationCheck(null);
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files.length > 1) {
        setMultipleSelectedDictationCheck(event.target.files);
        setAttachedDictationCheck(true);
      } else {
        setSelectedDictationCheck(event.target.files[0]);
        setAttachedDictationCheck(true);
      }
    }
  };

  const handleSubmitCheckFile = () => {
    setLoading(true);
    const { studentId } = location.state || {};
    const formData = new FormData();
    if (selectedDictationCheck) {
      formData.append("dictation_check_images", selectedDictationCheck);
    } else if (multipleSelectedDictationCheck) {
      for (let i = 0; i < multipleSelectedDictationCheck.length; i++) {
        formData.append(
          "dictation_check_images",
          multipleSelectedDictationCheck[i]
        );
      }
    }
    formData.append("student_id", studentId);
    UploadDictationCheck(formData);
  };

  const UploadDictationCheck = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/english/dictation/upload-student-answer`,
        formData
      );
      onUploadStudentAnswerSuccess(response.data.payload); // Call the new prop function
    } catch (error) {
      console.error("Error updating english progress:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDictationCheck) {
      setAttachmentDictationCheckFileText(selectedDictationCheck.name);
    } else if (multipleSelectedDictationCheck) {
      setAttachmentDictationCheckFileText(
        `${multipleSelectedDictationCheck.length} files selected`
      );
    } else {
      setAttachmentDictationCheckFileText("上傳答案");
    }
  }, [selectedDictationCheck, multipleSelectedDictationCheck]);

  return (
    <div>
      <button className="dictation-button" onClick={triggerCheckFileSelect}>
        📤{attachmentDictationCheckFileText}
      </button>
      <input
        id="dictation-check-image-input"
        type="file"
        style={{ display: "none" }}
        onChange={handleCheckFileSelect}
        multiple
      />
      {attachedDictationCheck ? (
        <button
          className="dictation-button"
          onClick={handleSubmitCheckFile}
          style={{ backgroundColor: "green" }}
        >
          提交你的答案
        </button>
      ) : (
        <button
          className="dictation-button"
          style={{ backgroundColor: "grey", color: "rgba(255, 255, 255, 0.7)" }}
        >
          提交你的答案
        </button>
      )}
    </div>
  );
};

export default DictationCheck;
