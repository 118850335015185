export class Player {  
    private playbackNode: AudioWorkletNode | null = null;  
    private audioContext: AudioContext | null = null;  
    
    async init(sampleRate: number) {  
      this.audioContext = new AudioContext({ sampleRate });  
      console.log("Audio context initialized:", this.audioContext);  
      await this.audioContext.audioWorklet.addModule(`${process.env.REACT_APP_PUBLIC_URL}/playback-worklet.js`);  
      this.playbackNode = new AudioWorkletNode(this.audioContext, "playback-worklet");  
      this.playbackNode.connect(this.audioContext.destination);  
    }  
    
    play(buffer: Int16Array) {  
      if (this.audioContext && this.audioContext.state === "suspended") {  
        this.audioContext.resume();  
      }  
      if (this.playbackNode) {  
        // console.log("Playing audio buffer:", buffer);  
        this.playbackNode.port.postMessage(buffer);  
      }  
    }  
    
    clear() {  
      if (this.playbackNode) {  
        this.playbackNode.port.postMessage(null);  
      }  
    }  
  }  
  