import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import searchingAnimationData from "../../assests/lotties/searchingAnimation.json";
import checkingAnimationData from "../../assests/lotties/checkingAnimation.json";
import writingAnimationData from "../../assests/lotties/writingAnimation.json";
import DictationUpload from "./component/DictationUpload";
import DictationConfirm from "./component/DictationConfirm";
import DictationCheck from "./component/DictationCheck";
import DictationAnswerConfirm from "./component/DictationAnswerConfirm"; // New import
import "./component/Dictation.css";
import "../../App.css";

interface DictationList {
  dictation_list: string[];
}
interface DictationConfirm {
  dictation_confirm: string[];
}
interface DictationCheck {
  [key: string]: {
    student_answer: string;
    wrong_words: string[];
    correct_words: string[];
  };
}

const getOptions = (animationData: any) => ({
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
});

const DictationPage: React.FC = () => {
  const [vocab, setVocab] = useState<string[]>([]);
  const [playCount, setPlayCount] = useState<number>(0);
  const [dictListLoading, setDictListLoading] = useState(false);
  const [dictConfirmLoading, setDictConfirmLoading] = useState(false);
  const [dictCheckLoading, setDictCheckLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [dictationListResponse, setDictationListResponse] =
    useState<DictationList>();
  const [dictationConfirmResponse, setDictationConfirmResponse] =
    useState<DictationConfirm>();
  const [dictationCheckResponse, setDictationCheckResponse] =
    useState<DictationCheck>();
  const [dictationCheckResult, setDictationCheckResult] =
    useState<DictationCheck>();
  const [editableDictationList, setEditableDictationList] = useState<string[]>(
    []
  );
  const [showEditableTextBox, setShowEditableTextBox] =
    useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [studentAnswers, setStudentAnswers] = useState<string[]>([]); // New state
  const [showAnswerConfirm, setShowAnswerConfirm] = useState<boolean>(false); // New state
  const [samanthaVoice, setSamanthaVoice] =
    useState<SpeechSynthesisVoice | null>(null); // New state

  useEffect(() => {
    const getSamanthaVoice = () => {
      const voices = window.speechSynthesis.getVoices();
      return voices.find((voice) => voice.name === "Nicky");
    };

    const setVoice = () => {
      const voice = getSamanthaVoice();
      if (voice) {
        setSamanthaVoice(voice);
      }
    };

    // Set the voice when the component mounts
    setVoice();
    // Ensure voices are loaded
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = setVoice;
    }
  }, []);

  const readOutLoud = async (words: string[]) => {
    const speakWord = (word: string, delay: number) => {
      return new Promise<void>((resolve) => {
        const msg = new SpeechSynthesisUtterance(word);
        if (samanthaVoice) {
          msg.voice = samanthaVoice; // Set the voice to Samantha
        }
        msg.rate = 0.6;
        msg.onend = () => {
          setTimeout(resolve, delay);
        };
        window.speechSynthesis.speak(msg);
      });
    };

    const delayBetweenWords = 5000;
    setIsPlaying(true);
    await speakWord("The dictation is going to start", 3000);
    for (const sentence of words) {
      await speakWord(sentence, delayBetweenWords);
      await speakWord(sentence, delayBetweenWords);
    }
    setIsPlaying(false);
  };

  const handleReplayAudio = () => {
    if (playCount < 3 && !isPlaying) {
      readOutLoud(vocab);
      setPlayCount(playCount + 1);
    }
  };

  useEffect(() => {
    const dictationList = dictationListResponse?.dictation_list || [];
    setEditableDictationList(dictationList);
  }, [dictationListResponse]);

  useEffect(() => {
    const dictationConfirm = dictationConfirmResponse?.dictation_confirm || [];
    setVocab(dictationConfirm);
  }, [dictationConfirmResponse]);

  useEffect(() => {
    setDictationCheckResult(dictationCheckResponse);
  }, [dictationCheckResponse]);

  const highlightWords = (text: string, words: string[], className: string) => {
    let highlightedText = text;
    words.sort((a, b) => b.length - a.length);
    words.forEach((word) => {
      const regex = new RegExp(
        `(${word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
        "gi"
      );
      highlightedText = highlightedText.replace(
        regex,
        `<span class="${className}">$1</span>`
      );
    });
    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };

  const handleRestartDictation = () => {
    setDictationListResponse(undefined);
    setDictationConfirmResponse(undefined);
    setDictationCheckResponse(undefined);
    setDictationCheckResult(undefined);
    setEditableDictationList([]);
    setShowEditableTextBox(false);
    setIsConfirmed(false);
    setIsChecked(false);
    setStudentAnswers([]);
    setShowAnswerConfirm(false);
    setPlayCount(0);
  };

  const handleRedoDictation = () => {
    setIsChecked(false);
    setShowAnswerConfirm(false);
    setDictationCheckResponse(undefined);
    setDictationCheckResult(undefined);
    setPlayCount(0);
  };

  return (
    <div className="page-container">
      <div className="dictation-button-container">
        <h1 className="dict-page-title">默書溫習</h1>
        {dictationListResponse?.dictation_list &&
        dictationListResponse.dictation_list.length > 0 &&
        isConfirmed &&
        !isChecked ? (
          <>
            <div>
              {!showAnswerConfirm ? (
                <button
                  className={`${
                    playCount >= 3 || isPlaying
                      ? "disabled-play"
                      : "dictation-button"
                  }`}
                  onClick={handleReplayAudio}
                  disabled={playCount >= 3 || isPlaying}
                >
                  {playCount < 3
                    ? `播放默書內容 (剩餘 ${3 - playCount}/3)`
                    : "Replay Limit Reached"}
                </button>
              ) : null}
            </div>
            {!isChecked ? (
              <DictationCheck
                dictationListResponse={dictationListResponse}
                onCheckSuccess={(response) =>
                  setDictationCheckResponse(response)
                }
                setLoading={setDictCheckLoading}
                onUploadStudentAnswerSuccess={(response) => {
                  setStudentAnswers(response);
                  setShowAnswerConfirm(true);
                }} // New prop
              />
            ) : null}
          </>
        ) : !isChecked ? (
          <DictationUpload
            onUploadSuccess={(response) => {
              setDictationListResponse({ dictation_list: response });
              setShowEditableTextBox(true);
              setIsConfirmed(false);
            }}
            setLoading={setDictListLoading}
          />
        ) : null}
        {dictListLoading ? (
          <>
            <Lottie
              options={getOptions(searchingAnimationData)}
              height={400}
              width={400}
            />
            <div className="dict-loading-title">AIBO老師睇緊你嘅默書範圍</div>
          </>
        ) : showEditableTextBox ? (
          dictConfirmLoading ? (
            <>
              <Lottie
                options={getOptions(writingAnimationData)}
                height={400}
                width={400}
              />
              <div className="dict-loading-title">
                好！等我寫低你嘅默書範圍!
              </div>
            </>
          ) : (
            <DictationConfirm
              editableDictationList={editableDictationList}
              setEditableDictationList={setEditableDictationList}
              onConfirmSuccess={(response) => {
                setDictationConfirmResponse({ dictation_confirm: response });
                setIsConfirmed(true);
                setShowEditableTextBox(false);
              }}
              setLoading={setDictConfirmLoading}
            />
          )
        ) : null}
        {dictCheckLoading ? (
          <>
            <Lottie
              options={getOptions(checkingAnimationData)}
              height={400}
              width={400}
            />
            <div className="dict-loading-title">好！等我改一改!</div>
          </>
        ) : showAnswerConfirm ? (
          <DictationAnswerConfirm
            studentAnswers={studentAnswers}
            onConfirmSuccess={(response) => {
              setDictationCheckResponse(response);
              setIsChecked(true);
              setShowAnswerConfirm(false);
            }}
            setLoading={setDictCheckLoading}
          />
        ) : (
          dictationCheckResult && (
            <>
              <div className="dictation-results">
                <h2>🧑🏻‍🏫老師改正</h2>
                {Object.entries(dictationCheckResult).map(
                  ([key, value], index) => (
                    <div className="check-answer-container" key={index}>
                      <div className="check-answer">
                        <div className="dict-index">
                          {index + 1}){" "}
                          {value.wrong_words.length > 0 ? "❌" : "✅"}
                        </div>
                        <br />
                        <div className="dict-title">正確答案:</div>
                        {highlightWords(
                          editableDictationList[index] || "",
                          value.correct_words,
                          "highlight-corr"
                        )}
                        <div className="dict-title">你的答案:</div>
                        {highlightWords(
                          value.student_answer,
                          value.wrong_words,
                          "highlight"
                        )}
                        {value.wrong_words.length > 0 && (
                          <>
                            <div className="dict-title">錯別字:</div>{" "}
                            {value.wrong_words.join(", ")}
                          </>
                        )}
                      </div>
                      <div className="divider"></div>
                    </div>
                  )
                )}
              </div>
              <div className="button-container">
                <button
                  className="dictation-button"
                  onClick={handleRestartDictation}
                >
                  重新開始
                </button>
                <button
                  className="dictation-button"
                  onClick={handleRedoDictation}
                >
                  再默多次
                </button>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default DictationPage;
