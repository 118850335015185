import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "../Modal/Modal";
import ChapterSelection from "../ChapterSelection/ChapterSelection";
import { FaBook } from "react-icons/fa";
import "./Bookshelf.css";
import { useLocation, useNavigate } from "react-router-dom";

interface Textbook {
  publisher: string;
  grade: string;
  subject: string;
}

interface BookshelfProps {
  studentId: string;
}

const Bookshelf: React.FC<BookshelfProps> = ({ studentId }) => {
  const [textbooks, setTextbooks] = useState<Textbook[]>([]);
  const [selectedTextbook, setSelectedTextbook] = useState<Textbook | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch textbooks from the backend
    axios
      .get(
        // `${process.env.REACT_APP_V3_API_ENDPOINT}/api/textbook/${studentId}/english`
        `${process.env.REACT_APP_V3_API_ENDPOINT}/aibo/v0/master/api/textbook/${studentId}/english`
      )
      .then((response) => {
        console.log(response.data.payload);
        setTextbooks(response.data.payload);
      })
      .catch((error) => {
        console.error("There was an error fetching the textbooks!", error);
      });
  }, [studentId]);

  const handleSelectTextbook = (textbook: Textbook) => {
    setSelectedTextbook(textbook);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTextbook(null);
  };

  const handleNagivateHome = () => {
    navigate("/student", { state: { studentId } });
  };

  return (
    <>
      <header className="header">
        <div className="header-left">
          <div className="title">
            <strong>你的書架</strong>
          </div>
        </div>
        <div className="header-right">
          <button className="header-button" onClick={handleNagivateHome}>
            Home
          </button>
        </div>
      </header>
      <div className="unique-bookshelf">
        <ul className="unique-bookshelf-list">
          {textbooks.map((textbook, index) => (
            <li key={index} className="unique-bookshelf-item">
              <button
                className="unique-book-button"
                onClick={() => handleSelectTextbook(textbook)}
              >
                <div className="book-title">
                  <FaBook />

                  {textbook.publisher.charAt(0).toUpperCase() +
                    textbook.publisher.slice(1)}
                  <div>
                    (
                    {textbook.subject.charAt(0).toUpperCase() +
                      textbook.subject.slice(1)}
                    )
                  </div>
                </div>
              </button>
            </li>
          ))}
        </ul>
        <Modal show={isModalOpen} onClose={closeModal}>
          {selectedTextbook && (
            <ChapterSelection
              textbook={selectedTextbook}
              studentId={studentId}
              closeModal={closeModal}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default Bookshelf;
