import React, { useState } from "react";
import Rroutes from "./routes"; // Import your routes

const App: React.FC = () => {
  const [studentID, setStudentID] = useState<string | null>(null); // State for studentID

  return (
    <div>
      <Rroutes studentID={studentID} setStudentID={setStudentID} />{" "}
      {/* Pass studentID and setter */}
    </div>
  );
};

export default App;
