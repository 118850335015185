import React, { useEffect, useState } from "react";
import "./StudentApp.css";
import { useNavigate, useLocation } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import SearchBar from "../SearchBar/SearchBar";
import Grid from "@mui/material/Grid";
import Card from "../Card/Card";
import { CgProfile } from "react-icons/cg";
import { FaBook } from "react-icons/fa6";
import { FaPencilAlt } from "react-icons/fa";
import { IoLibrary } from "react-icons/io5";

const cardsData = [
  // {
  //   title: "AI Chatbot",
  //   description:
  //     "Create some practice questions for a specific piece of content.",
  //   icon: "🔍",
  //   color: "#FFE4E1",
  //   page: "noteschat",
  // },
  // {
  //   title: "Math Chatbot",
  //   description:
  //     "Create some practice questions for a specific piece of content.",
  //   icon: "🔍",
  //   color: "#FFE4E1",
  //   page: "mathchat",
  // },
  // {
  //   title: "Speaking",
  //   description:
  //     "Review and evaluate the summarized performance of the student.",
  //   icon: "📈",
  //   color: "#E0FFE1",
  //   page: "pronounce",
  // },
  // {
  //   title: "Main",
  //   description: "Main e-learning Page",
  //   icon: "📈",
  //   color: "#E0FFE1",
  //   page: "main",
  // },
  {
    title: "個人檔案",
    description: "按此看看自己的資料",
    icon: <CgProfile color="black" />,
    color: "#E0FFE1",
    page: "profile",
  },
  {
    title: "課堂",
    description: "按此開始和AIBO上堂啦！",
    icon: <FaBook color="black" />,
    color: "#E0FFE1",
    page: "handbook",
  },
  {
    title: "默書",
    description: "按此和AIBO默書啦！",
    icon: <FaPencilAlt color="black" />,
    color: "#E0FFE1",
    page: "dictation",
  },
  {
    title: "你的書架",
    description: "按此看看自己的書架",
    icon: <IoLibrary color="black" />,
    color: "#E0FFE1",
    page: "bookshelf",
  },
];

const SApp: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string>("all");
  const [studentId, setStudentId] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // type CategoryKey = keyof typeof categorizedData;
  const handleCategoryChange = (category: string) => {
    setActiveCategory(category);
  };

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
  }, []);

  const handleCardClick = (title: string, studentId: string) => {
    if (studentId) {
      navigate(`/${title}`, { state: { studentId } });
    } else {
      alert("Please enter a student ID first.");
    }
  };

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const studentIdInput = (event.target as any).studentId;
    setStudentId(studentIdInput.value);
  };

  return (
    <div className="App">
      {!studentId && (
        <form onSubmit={handleLogin}>
          <label htmlFor="studentId">Enter Student ID:</label>
          <input type="text" id="studentId" name="studentId" />
          <button type="submit">Login</button>
        </form>
      )}
      {studentId && (
        <div className="card-grid">
          <Grid container spacing={6}>
            {/* Cards layout */}
            {cardsData.map((card, index) => (
              <Grid item key={index} xs={4}>
                <div className="card-container">
                  <Card
                    title={card.title}
                    description={card.description}
                    icon={card.icon}
                    color="#21B0ED"
                    onClick={() => handleCardClick(card.page, studentId)}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default SApp;
